import { toCommaSeparatedListString } from 'apps/acp/packages/comma-separated-list-string';
import { getCurrentPlatform, Platform } from 'packages/platform-detector';
import { Anchor } from 'packages/react-nano-router';
import { CordovaGlobal } from 'apps/acp/packages/environment';

export interface InAppBrowserOptions {
  headertext?: string;
  toolbarcolor?: string;
  toolbartranslucent?: 'yes' | 'no';
}

export const createOpenWithInAppBrowserAnchor = (
  href: string,
  options: InAppBrowserOptions = {},
  attachedFunctions: Record<
    string,
    (closeInAppBrowser: () => void) => (arg?: any) => void
  > = {}
): Anchor => {
  const currentPlatform = getCurrentPlatform();
  return {
    href,
    onClick(event) {
      if (
        currentPlatform === Platform.iOSCordova ||
        currentPlatform === Platform.AndroidCordova
      ) {
        event && event.preventDefault();
        const inAppBrowserRef = ((window as any)
          .cordova as CordovaGlobal).InAppBrowser.open(
          href,
          '_blank',
          toCommaSeparatedListString(options as Record<string, string>)
        );
        const closeInAppBrowser = () => inAppBrowserRef.close();
        Object.entries(attachedFunctions).forEach(([functionName, fn]) => {
          // this setTimeout is a hack to fix functions not always being attached. - nik
          //  Perhaps we need to wait for the inAppBrowser page to be "ready"
          setTimeout(() => {
            inAppBrowserRef.attachFunction(functionName, (...args) =>
              fn(closeInAppBrowser)(...args)
            );
          }, 3000);
        });
      }
    }
  };
};
